<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else >
      <loading :loading="loading" />
    <!-- <div v-if="data_customer.length != 0"> -->

        <!-- <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only"> -->
            <div>
              <v-row>
                <v-col cols="4" class="pa-0">
                  <v-card elevation="0">
                    <v-card-text class="pa-3">
                      <v-row>
                        <v-col cols="2" class="pa-0">
                          <v-avatar size="36px" class="mt-1">
                            <img alt="Avatar" :src="page_logo">
                          </v-avatar>
                        </v-col>
                        <v-col cols="10" class="pa-0">
                          <v-text-field rounded hide-details label="ค้นหารายชื่อ" outlined></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="8" class="pa-0 pl-1">
                  <v-card elevation="0">
                    <v-card-text class="pa-3 pb-4">
                      <v-avatar size="36px" class="mt-1">
                        <img alt="Avatar" v-if="chat_logo != null" :src="chat_logo">
                      </v-avatar>
                      <span v-if="chat_name != null" class="ml-2"> <b>{{ chat_name }}</b> </span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="mt-1">
                <v-col cols="4" class="pa-0">
                  <v-card elevation="0"  height="600px">
                    <v-card-text class="pa-1">
                      <v-row>
                        <v-col cols="12">
                          <h1>Chat</h1>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-0 pt-2 overflow-y-auto" style="height: 520px">
                          <v-card elevation="0" v-for="item in friend_list" :key="item.id" height="50px" @click="select_friend(item)">
                            <v-card-text class="py-1 px-2">
                              <v-row>
                                <v-col cols="10" class="pa-0">
                                  <v-badge
                                    v-if="item.notify > 0"
                                    :content="item.notify"
                                    :value="item.notify"
                                    color="green"
                                    left
                                    overlap
                                  >
                                  <v-avatar size="36px" class="mt-1">
                                    <img alt="Avatar" :src="item.logo">
                                  </v-avatar>
                                  </v-badge>
                                  <v-avatar v-else size="36px" class="mt-1">
                                    <img alt="Avatar" :src="item.logo">
                                  </v-avatar>

                                  <span class="ml-2"> <b>{{ item.name }}</b> </span>
                                </v-col>
                                <v-col cols="2" class="pa-0">
                                  <div align="right">
                                    {{ item.time }}
                                  </div>
                                  <div align="right"></div>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-divider/>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="8" class="pa-0 pl-1">
                  <v-card elevation="0"  height="600px">
                    <v-card-text class="pa-1">
                      <v-row>
                        <v-col class="pa-0">
                          <v-card elevation="0">
                            <v-card-text class="pa-0 overflow-y-auto" style="height: 520px; background: #F7F7F7">
                              <div v-if="chat_logo != null">
                                <div v-for="item_ms in chat_list" :key="item_ms.id">
                                  <v-row  class="mb-2" v-if="item_ms.user_type == 1">
                                     <!-- <v-col cols="1">
                                      <v-avatar size="36px">
                                        <img :src="chat_logo" v-if="item_ms.show_logo == 1">
                                      </v-avatar>
                                     </v-col>
                                     <v-col cols="9">
                                       <span class="css_for_customer" v-if="item_ms.message_type == 1">
                                         {{ item_ms.messages }}
                                       </span>
                                       <span v-else >
                                         <img :src="item_ms.messages">
                                       </span>
                                     </v-col> -->
                                     <div class="container">
                                        <img :src="chat_logo" class=" mr-5" alt="Avatar" style="width:100%;">
                                        <p v-if="item_ms.message_type == 1">{{ item_ms.messages }}</p>
                                        <p v-else><img :src="item_ms.messages"></p>
                                        <span class="time-right">11:00</span>
                                      </div>
                                  </v-row>

                                  <v-row class="mb-1 leftt"  v-else>
                                    <!-- <v-col cols="1"></v-col>
                                    <v-col cols="9" align="right">
                                       <div class="css_for_page" v-if="item_ms.message_type == 1">
                                         {{ item_ms.messages }}
                                       </div>
                                       <div v-else>
                                         <img :src="item_ms.messages">
                                       </div>
                                    </v-col>
                                     <v-col cols="1" >
                                       <v-avatar size="36px">
                                         <img :src="page_logo" v-if="item_ms.show_logo == 1">
                                       </v-avatar>
                                     </v-col> -->
                                     <div class="container darker">
                                       <img :src="page_logo" alt="Avatar" class="right ml-5" style="width:100%;">

                                        <p v-if="item_ms.message_type == 1">{{ item_ms.messages }}</p>
                                        <p v-else><img :src="item_ms.messages"></p>
                                        <span class="time-left">11:00</span>
                                      </div>
                                  </v-row>
                                </div>
                              </div>

                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="px-0">
                          <v-text-field
                            v-model="message"
                            :append-outer-icon="message ? 'mdi-send' : 'mdi-send'"
                            filled
                            label="Message"
                            type="text"
                            @click:append-outer="message = null"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
        <!-- </v-card> -->

        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
            <v-card-text>
              <v-btn color="blue">
                <fb-signin-button
                  :params="fbSignInParams"
                  @success="onSignInSuccess"
                  @error="onSignInError">
                  Sign in with Facebook
                </fb-signin-button>
              </v-btn>
            </v-card-text>
        </v-card>


        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
            <v-card-text>
              <v-btn dark color="blue" @click="convrt_token_shot_to_log()">
                ขยายเวลา token login and ดึงข้อมูล รูปภาพ

              </v-btn>
            </v-card-text>
        </v-card>

        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
            <v-card-text>
              <v-btn dark color="blue" @click="get_all_page_fb_by_id_fb()">
                get all page fb by id fb

              </v-btn>
            </v-card-text>
        </v-card>

        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
            <v-card-text>
              <v-btn dark color="blue" @click="get_data_user()">
                get data user fb

              </v-btn>
            </v-card-text>
        </v-card>

        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
            <v-card-text>
              <v-btn dark color="blue" @click="get_token_page()">
                get token page
              </v-btn>
            </v-card-text>
        </v-card>

        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
            <v-card-text>
              <v-btn dark color="blue" @click="sendmessage()">
                send message

              </v-btn>
            </v-card-text>
        </v-card>

        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
            <v-card-text>
              <v-btn dark color="blue" @click="subscribed()">
                get subscribed
              </v-btn>
            </v-card-text>
        </v-card>

    </div>
</template>
<script>
    import Loading from '@/website/components/Loading'
    import axios from 'axios'
    import { generalService_dotnet,shopService_dotnet } from '@/website/global'
    import { isNumberWNoDot ,get_languages} from '@/website/global_function'
    import { token_api  } from '@/website/backend/token'

    export default {
        components: {
            Loading
        },
        data:() => ({
          header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
            page_loading: true,
            loading: false,

            fbSignInParams: {
              scope: "email,public_profile",
              return_scopes: true,
            },

            token_login: null,
            id_login: null,


            // send message bottom
            message: 'Hey!',
            page_logo : "https://avatars0.githubusercontent.com/u/9564066?v=4&s=460",

            friend_list : [
              { "id": 1, "logo":"https://avatars0.githubusercontent.com/u/9064065?v=4&s=460" ,"name":"Loren mark", "time": "9:10 AM", "notify":1},
              { "id": 2, "logo":"https://avatars0.githubusercontent.com/u/9064066?v=4&s=460" ,"name":"Stefen jerrag", "time": "9:05 AM", "notify":2},
              { "id": 3, "logo":"https://avatars0.githubusercontent.com/u/9064265?v=4&s=460" ,"name":"Lucas fernades", "time": "9:04 AM", "notify":3},
              { "id": 4, "logo":"https://avatars0.githubusercontent.com/u/9064465?v=4&s=460" ,"name":"Baba mulan", "time": "9:03 AM", "notify":2},
              { "id": 5, "logo":"https://avatars0.githubusercontent.com/u/9064565?v=4&s=460" ,"name":"Cristian bling", "time": "9:02 AM", "notify":3},
              { "id": 6, "logo":"https://avatars0.githubusercontent.com/u/9064065?v=4&s=460" ,"name":"Loren mark", "time": "9:01 AM", "notify":1},
              { "id": 7, "logo":"https://avatars0.githubusercontent.com/u/9064066?v=4&s=460" ,"name":"Stefen jerrag", "time": "9:00 AM", "notify":0},
              { "id": 8, "logo":"https://avatars0.githubusercontent.com/u/9064265?v=4&s=460" ,"name":"Lucas fernades", "time": "9:00 AM", "notify":0},
              { "id": 9, "logo":"https://avatars0.githubusercontent.com/u/9064465?v=4&s=460" ,"name":"Baba mulan", "time": "9:00 AM", "notify":0},
              { "id": 10, "logo":"https://avatars0.githubusercontent.com/u/9064565?v=4&s=460" ,"name":"Cristian bling", "time": "9:00 AM", "notify":0},
              { "id": 11, "logo":"https://avatars0.githubusercontent.com/u/9064065?v=4&s=460" ,"name":"Loren mark", "time": "9:00 AM", "notify":0},
              { "id": 12, "logo":"https://avatars0.githubusercontent.com/u/9064066?v=4&s=460" ,"name":"Stefen jerrag", "time": "9:00 AM", "notify":0},
              { "id": 13, "logo":"https://avatars0.githubusercontent.com/u/9064265?v=4&s=460" ,"name":"Lucas fernades", "time": "9:00 AM", "notify":0},
              { "id": 14, "logo":"https://avatars0.githubusercontent.com/u/9064465?v=4&s=460" ,"name":"Baba mulan", "time": "9:00 AM", "notify":0},
              { "id": 15, "logo":"https://avatars0.githubusercontent.com/u/9064565?v=4&s=460" ,"name":"Cristian bling", "time": "9:00 AM", "notify":0},
            ],

            chat_logo: null,
            chat_name: null,

            chat_list: [],

            // paginations
            page: 1,
            pageTemp: 1,
            pageLength: 1,
            pageTotalVisible: 9,
            limits: 50,
            offsets: 0,
        }),
        async created() {

       //เก็บภาษามาไว้ใน use_language


            this.loading = true
            this.ShopID = localStorage.getItem("shop_id")
            await this.facebooksignup();
            this.page_loading = false
            this.loading = false

        },

        // name:"facebookLogin",
        methods: {
            isNumberWNoDot,get_languages,
            onSignInError(error) {
              console.log("OH NOES", error);
            },

            async onSignInSuccess(response) {
              // console.log(response);
              console.log(response.authResponse.accessToken);
              this.token_login = response.authResponse.accessToken
              window.FB.api(
                "/me",
                "GET",
                { fields: "id,name,email,picture" },
                async (userInformation) => {
                  console.log("yes");
                  console.log(userInformation);
                  this.id_login = userInformation.id

                  if (userInformation.id) {
                    console.log("");
                  } else {
                    console.log("User cancelled login or did not fully authorize.");
                  }
                }
              );
            },

            async facebooksignup(){
              window.fbAsyncInit = function() {
                window.FB.init({
                  appId      : '849982039770741', //'849982039770741',//'690904239189374', // 2978410389108595 / 690904239189374
                  cookie     : true,  // enable cookies to allow the server to access the session
                  xfbml      : true,  // parse social plugins on this page
                  version    : 'v2.8' // use graph api version 2.8
                });
              };
              (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "//connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));
            },

            async convrt_token_shot_to_log () {
              var app_id = "849982039770741"
              var app_secret = "79e51729474c1684a2890363a327dacc"
              let response = await axios.get("https://graph.facebook.com/v16.0/oauth/access_token?grant_type=fb_exchange_token&client_id=" + app_id + "&client_secret=" + app_secret + "&fb_exchange_token=" + this.token_login, {})
              console.log(response);
              this.token_login = response.data.access_token

              // gen long page token
              let response_page = await axios.get("https://graph.facebook.com/v16.0/" + this.id_login + "/accounts?access_token=" + this.token_login , {})
              console.log(response_page);

              for (var item of response_page.data.data) {
                let response_page_image = await axios.get("https://graph.facebook.com/v16.0/me?fields=id,name,picture&access_token=" + item.access_token , {})
                console.log(response_page_image);
                item['logo'] = response_page_image.data.picture.data.url
              }

              console.log(response_page);
            },

            async get_all_page_fb_by_id_fb () {
              // var page_id = "113157428406374"
              // var page_token = "EAAMdzcy0SNcBALicgvwqZBNRL5SS14OZCNqODyCUrVzRzKr8VmRZAvs4flUeXRZCdymmVPqFSvV2EACJx58EwW77W5nFd2zu0kDvD7v1WPczDAxx1iZCemNgwZALAl2BY9cJOGITWsW1bJZADvaY643hFZCkbStJp84n8Bs3PG8GXQWcLOYRTf1K"
              // var psid = "6498629320171034"
              let response = await axios.get("https://graph.facebook.com/v16.0/104142725908001/accounts?access_token=" + this.token_login, {})
              // let response = await axios.get("https://graph.facebook.com/v16.0/104142725908001/accounts?access_token=EAAMFDd2LxnUBAFTHZCjjWthdperNn407BzfTxfzeBkUEZAOqy796imzanL1prWYy0QC7ZB4ZAJN1AjMialU9gxRAt635YCpIS2qNoYTZBYYyue7EROZAH08mGtKV1tcI02ZCgSIZCcXiyKx9z2GmY0n17qQ3Ok6szL1ZBr8sjgkXG3PBrRCsNwnGHni08N0gOqPvZChkHumZCVn1ZBX89CYR9EJ5", {})
              console.log(response);
            },

            async get_data_user () {
              // var page_id = "113157428406374"
              // var page_token = "EAAMdzcy0SNcBALicgvwqZBNRL5SS14OZCNqODyCUrVzRzKr8VmRZAvs4flUeXRZCdymmVPqFSvV2EACJx58EwW77W5nFd2zu0kDvD7v1WPczDAxx1iZCemNgwZALAl2BY9cJOGITWsW1bJZADvaY643hFZCkbStJp84n8Bs3PG8GXQWcLOYRTf1K"
              // var psid = "6498629320171034"
              let response = await axios.post("https://graph.facebook.com/v16.0/me?fields=id,name,age_range,birthday&access_token=" + this.token_login, {})
              console.log(response);
            },

            async get_token_page () {
              var page_id = "108401865472300"
              // var page_token = "EAAMdzcy0SNcBALicgvwqZBNRL5SS14OZCNqODyCUrVzRzKr8VmRZAvs4flUeXRZCdymmVPqFSvV2EACJx58EwW77W5nFd2zu0kDvD7v1WPczDAxx1iZCemNgwZALAl2BY9cJOGITWsW1bJZADvaY643hFZCkbStJp84n8Bs3PG8GXQWcLOYRTf1K"
              // var psid = "6498629320171034"
              let response = await axios.get("https://graph.facebook.com/" + page_id + "?fields=access_token&access_token="+ this.token_login, {})
              console.log(response);
            },


            async sendmessage () {
              var page_id = "113157428406374"
              var page_token = "EAAMdzcy0SNcBALicgvwqZBNRL5SS14OZCNqODyCUrVzRzKr8VmRZAvs4flUeXRZCdymmVPqFSvV2EACJx58EwW77W5nFd2zu0kDvD7v1WPczDAxx1iZCemNgwZALAl2BY9cJOGITWsW1bJZADvaY643hFZCkbStJp84n8Bs3PG8GXQWcLOYRTf1K"
              var psid = "6498629320171034"
              let response = await axios.post("https://graph.facebook.com/v16.0/"+ page_id +"/messages?recipient={'id':'" + psid + "'}&messaging_type=RESPONSE&message={'text':'เต๋า 52'}&access_token=" + page_token, {})
              console.log(response);
            },

            async subscribed () {
              var page_id = "113157428406374"
              var page_token = "EAAMdzcy0SNcBALicgvwqZBNRL5SS14OZCNqODyCUrVzRzKr8VmRZAvs4flUeXRZCdymmVPqFSvV2EACJx58EwW77W5nFd2zu0kDvD7v1WPczDAxx1iZCemNgwZALAl2BY9cJOGITWsW1bJZADvaY643hFZCkbStJp84n8Bs3PG8GXQWcLOYRTf1K"
              var psid = "6498629320171034"
              let response = await axios.get("https://graph.facebook.com/v16.0/" + page_id + "/personas", {})
              console.log(response);
            },

            select_friend (item) {
              console.log(item);
              this.chat_logo = item.logo
              this.chat_name = item.name
              item.notify = 0

              // ตัวอย่าง
              // message_type 1 == ข้อความ , 2 == รูปภาบแบบ link
              // user_type 0 user page , 1 user cutomer
              this.chat_list = []
              if(item.id == 1){
                this.chat_list=[
                  {"id": 1, "message_type": 1, "messages" : "สวัสดีครับ" , "user_type": 1, "show_logo": null},
                  {"id": 2, "message_type": 1, "messages" : "สนใจสินอะอะไร ของเพจเราสอบถามได้เลยนะครับ aaaaaaaaaaaasddsdlakl;sdka;ldskl;askdasdasdasdasd aaaaaaaaaaaasddsdlakl;sdka;ldskl;askdasdasdasdasdaaaaaaaaaaaasddsdlakl;sdka;ldskl;askdasdasdasdasd" , "user_type": 0, "show_logo": null},
                  {"id": 3, "message_type": 1, "messages" : "สนใจสินค้าชิ้นนี้ครับ" , "user_type": 1, "show_logo": null},
                  {"id": 4, "message_type": 2, "messages" : "https://rtms3.s3.ap-southeast-1.amazonaws.com/restaurant/2019-09-06T06%3A37%3A02.056Z" , "user_type": 1, "show_logo": null},
                  {"id": 5, "message_type": 1, "messages" : "มีหลายแบบให้เลือกเลยครับ ลูกค้าสนใจสีไหนครับ" , "user_type": 0, "show_logo": null},
                ]
              }else if (item.id == 2) {
                this.chat_list=[
                  {"id": 1, "message_type": 1, "messages" : "สวัสดีค่ะ" , "user_type": 1, "show_logo": null},
                  {"id": 2, "message_type": 1, "messages" : "ใช่เพจขายเสื้อผ้าไหมค่ะ" , "user_type": 1, "show_logo": null},
                  {"id": 3, "message_type": 1, "messages" : "ใช่ ครับสนใจเสื้อผ้าแบบไหนครับ" , "user_type": 0, "show_logo": null},
                ]
              }else if (item.id == 3) {
                this.chat_list=[
                  {"id": 1, "message_type": 1, "messages" : "สวัสดีครับ" , "user_type": 1, "show_logo": null},
                  {"id": 2, "message_type": 1, "messages" : "สนใจสินอะอะไร ของเพจเราสอบถามได้เลยนะครับ" , "user_type": 0, "show_logo": null},
                  {"id": 3, "message_type": 1, "messages" : "มีน้ำนี้ขายไหมครับ" , "user_type": 1, "show_logo": null},
                  {"id": 4, "message_type": 2, "messages" : "https://rtms3.s3.ap-southeast-1.amazonaws.com/restaurant/restId149/menus/2019-11-28T07%3A16%3A56.569Z" , "user_type": 1, "show_logo": null},
                  {"id": 5, "message_type": 1, "messages" : "มีครับ ลูกค้าอยากได้กี่ขวดครับ" , "user_type": 0, "show_logo": null},
                  {"id": 6, "message_type": 1, "messages" : "อยากได้ 1 แพคครับ" , "user_type": 1, "show_logo": null},
                ]
              }


              // check logo show
              var logo_check = null
              for (var item_c of this.chat_list) {
                if(logo_check != item_c.user_type){
                  item_c.show_logo = 1
                }else {
                  item_c.show_logo = 0
                }
                logo_check = item_c.user_type


              }
            },

        }
    }
</script>

<style scoped>

  .fb-signin-button {
    display: inline-block;
    padding: 17px;
    width: 100%;
    border-radius: 3px;
    /* background-color: #4267b2; */
    color: #fff;
  }

  .css_for_customer{
    background-color: #44c35f;
    max-width: 95% !important;
    height: 100%;
    border: 1px solid;
    padding: 15px !important;
    border-radius: 20px;
    overflow: visible;
    color: white;
}
.css_for_page{
 background-color: #ea5455;
    max-width: 95% !important;
    height: 100%;
    border: 1px solid;
    padding: 15px !important;
    border-radius: 20px;
    overflow: visible;
    color: white;
    text-align: right;
}
.leftt{
      display: flex;
    justify-content: flex-end;
}

.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.container img {
  float: left;
  max-width: 60px;
  width: 100%;
  /* margin-right: 20px; */
  border-radius: 50%;
}

.container img.right {
  float: right;
  margin-left: 20px;
  margin-right:0;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}

</style>
