<template>
  <!-- <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div> -->
  <div>
    <!-- <loading :loading="loading" /> -->
    <v-card elevation="1" class="v-card-bottom-30 ">
      <v-card-title>
        <h4>ตัวแทน</h4>
        <v-spacer />
        <v-btn @click="get_ref_code() ,dialogAddAgent = true"  dark color="primary"
          ><v-icon left>mdi-plus</v-icon> เพิ่ม</v-btn
        >
      </v-card-title>
    </v-card>

    <!-- treeview -->
    <v-card>
      <v-row>
        <v-col>
          <v-card-text>
            <v-treeview
              v-model="tree"
              :active.sync="active"
              :load-children="getAgentLevel"
              :items="items"
              :open.sync="open"
              open-on-click
              return-object
              expand-icon="mdi-chevron-down"
            >
            </v-treeview>
          </v-card-text>
        </v-col>

        <v-divider vertical></v-divider>

        <!-- <v-col cols="12" md="6">
          <v-card-text>
          </v-card-text>
        </v-col> -->

        <v-col
        class="d-flex text-center"
      >
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
            class="text-h6 grey--text text--lighten-1 font-weight-light"
            style="align-self: center;"
          >
            Select a User
          </div>
          <v-card
            v-else
            :key="selected.id"
            class="pt-6 mx-auto"
            flat
            max-width="400"
          >
            <v-card-text>
              <h3 class="text-h5 mb-2">
                {{ selected.shopAgentName }}
              </h3>
              <div class="blue--text mb-2">
                {{ selected.email }}
              </div>
              <div class="blue--text subheading font-weight-bold">
                {{ selected.username }}
              </div>
            </v-card-text>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
      </v-row>
    </v-card>
    <!-- treeview -->

    <!-- <v-card>
      <v-btn color="success" @click="fetchAgentLevel">text</v-btn>
      <v-btn color="error" @click="fetch">text</v-btn>
    </v-card> -->

    <!-- copy url -->
    <v-dialog v-model="dialogAddAgent" scrollable max-width="700" persistent>
      <v-card class="my-0">
        <v-card-title>
          <span class="headline">ลิงก์สำหรับตัวแทน</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeAddAgent()">
            <v-icon color="danger">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-list-item>
          <v-input type="text">{{refcode}}
            <v-spacer/>
            <v-btn color="primary" @click="copyUrl()">
               copy
            </v-btn>
          </v-input>
        </v-list-item>
      </v-card>
    </v-dialog>
    <!-- copy url -->

  </div>
</template>

<script>
import axios from "axios";
// import Loading from '@/website/components/Loading'
import { agentService_dotnet } from "@/website/global";
import { header_token } from "@/website/backend/token";

const data = { ShopID: localStorage.getItem("shop_id"),
               BranchID: localStorage.getItem("branch_id"), };
export default {
  components: {
    // Loading,
  },
  data: () => ({
      dialogAddAgent: false,
      refcode:'',
      // page_loading: true,
      // loading: false,

      //treeview
      level: [],
      isLoading: false,
      tree: [],
      types: [],
      agentlevel: [],
      masterName: '',
      active: [],
      users: [],
      open: [],
  }),

  computed: {
    //treeview
    items () {
      const children = this.types.map(type => ({
        id: type,
        name: this.getName(type),
        children: this.getChildren(type),
      }))

      return [{
        id: 1,
        name: "Shop Master: "+this.masterName,
        children,
      }]
    },
    shouldShowTree () {
      return this.agentlevel.length > 0 && !this.isLoading
    },

    selected () {
        if (!this.active.length) return undefined

        const id = this.active[0]

        return this.types.find(types => types.id === id)
      },
    //treeview

  },

  watch: {
    //treeview
    agentlevel (val) {
      this.types = val.reduce((acc, cur) => {
        const type = cur.shopHost

        if (!acc.includes(type)) acc.push(type)

        return acc
      }, []).sort()
    },
    //treeview

  },

  async created() {
    //treeview
    this.getAgentLevel ()
    //treeview

  },

  methods: {
    closeAddAgent() {
      this.dialogAddAgent = false;
    },
    copyUrl () {
      try{
        navigator.clipboard.writeText(this.refcode)
      } catch(e) {
        throw e
      }
    },
    async get_ref_code() {
      // this.loading = true
      let response = await axios.post(
        agentService_dotnet+'Permission/get-ref-code',
        {
          'shopId': localStorage.getItem("shop_id"),
        },
        { headers: header_token }
      );
      this.refcode = response.data;
      console.log(response.data);
    },

    //treeview
    async getAgentLevel (){
      if (this.agentlevel.length) return
      let response = await axios.post("https://localhost:5001/AgentLevel/agent-level-tree",
        {
          'ShopID': localStorage.getItem("shop_id"),
          'BranchID': localStorage.getItem("branch_id"),
        },
        { headers: header_token }
      );
      this.masterName = response.data.shopName
      this.agentlevel = response.data.agentLevelDetail
    },

    getChildren (type) {
      const agentlevel = []

      for (const aLevel of this.agentlevel) {
        if (aLevel.shopHost !== type) continue

        agentlevel.push({
          ...aLevel,
          name: this.getName(aLevel.shopAgentName),
        })
      }

      return agentlevel.sort((a, b) => {
        return a.shopAgentName > b.shopAgentName ? 1 : -1
      })
    },
    getName (shopAgentName) {
      return `${shopAgentName.charAt(0).toUpperCase()}${shopAgentName.slice(1)}`
    },
    //treeview

  },
};
</script>
