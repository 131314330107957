<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
        <loading :loading="loading" />
        <v-card elevation="1"  class="v-card-bottom-30">
            <v-card-text>
                <div class="mb-2">
                    <v-row justify="center" align="center">
                        <v-col class="pxy-0" cols="6" md="6"> <h2>搜索</h2> </v-col>
                        <v-col class="pxy-0" cols="6" md="6" align = "right">
                            <v-btn color="primary" class="px-2 mr-1" @click="Addtracking()"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </div>
                <v-divider/>
                <div>
                    <!-- เลขกระสอบ -->
                    <v-row row >
                        <v-col class="pb-0" cols="12" md="3"> <h3 class="fn-14"> 包裹号码  : </h3></v-col>
                        <v-col class="pb-0" cols="12" md="4" >
                            <v-text-field
                                v-model="search.packing"
                                @change="value => search.packing = value"
                                outlined
                                dense
                                hide-details
                                autocomplete="off"
                                />
                        </v-col>
                    </v-row>
                    <!-- เลข Track -->
                    <v-row row >
                        <v-col class="pb-0" cols="12" md="3"> <h3 class="fn-14"> 运单号 : </h3></v-col>
                        <v-col class="pb-0" cols="12" md="4" >
                            <v-text-field
                                v-model="search.track"
                                outlined
                                dense
                                hide-details
                                autocomplete="off"
                                />
                        </v-col>
                    </v-row>
                    <!-- วันที่ -->
                    <v-row row >
                        <v-col class="pb-0" cols="12" md="3"> <h3 class="fn-14"> 日期 : </h3></v-col>
                        <v-col cols="12" md="2">
                            <v-menu
                                ref="search_menu_date_from"
                                v-model="search_menu_date_from"
                                :close-on-content-click="false"
                                :return-value.sync="search_date_from"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    :value="computed_search_date_from"
                                    @change="value => computed_search_date_from = value"
                                    autocomplete="off"
                                    label="开始"
                                    outlined
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    hide-details
                                    dense
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-menu
                                ref="search_menu_date_to"
                                v-model="search_menu_date_to"
                                :close-on-content-click="false"
                                :return-value.sync="search_date_to"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :value="computed_search_date_to"
                                        @change="value => computed_search_date_to = value"
                                        autocomplete="off"
                                        label="结束"
                                        outlined
                                        prepend-inner-icon="mdi-calendar"
                                        readonly
                                        hide-details
                                        dense
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <!-- ค้นหา -->
                    <v-row>
                        <v-col class="pb-0" cols="12" md="3"></v-col>
                        <v-col class="pb-0" cols="12" md="4">
                            <v-btn dark color="primary" @click="getsearchdatapack()" class="mr-3">搜索</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
        <v-card class="v-card-bottom-30">
            <!-- รายละเอียด,Export to Excel  // 出口到Excel -->
            <v-card-title> 详情  <v-spacer/> <v-btn color="success" outlined @click="Export_to_excel()">Export to Excel</v-btn> </v-card-title>
            <v-card-text>
                <!-- ออกของ -->
                <div class="pb-2"><v-btn color="success" outlined @click="outof()" >出货</v-btn> </div>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="dataTable"
                    :single-select="singleSelect"
                    item-key="ID"
                    class="elevation-0 packhai-border-table"
                    hide-default-footer
                    show-select
                    :items-per-page="50">
                    <!-- <template v-slot:item.Status="{ item }">
                        <v-btn icon color="danger" @click="detete_topup(item.ID, item.File_url)" v-if="item.Status == TopupStatusDict['WaitCheck']"><v-icon size="18" >fa-trash-alt</v-icon></v-btn>
                        <v-btn icon color="danger" @click="detete()" ><v-icon size="18" >fa-trash-alt</v-icon></v-btn>
                    </template> -->
                    <template v-slot:item.edit="{ item }"> <v-btn icon @click="Edit(item)"> <v-icon color="info">mdi-pencil</v-icon></v-btn> </template>
                    <template v-slot:item.PackingID="{ item }" ><a @click="loaddetailtrack(item.ID)">{{item.ID}}</a></template>
                    <template v-slot:item.created="{ item }">{{item.Created}}</template>
                    <template v-slot:item.weight="{ item }">{{item.Weight}}</template>
                    <template v-slot:item.width="{ item }">{{item.Cubic1}}</template>
                    <template v-slot:item.lenght="{ item }">{{item.Cubic2}}</template>
                    <template v-slot:item.height="{ item }">{{item.Cubic3}}</template>
                    <template v-slot:item.by="{ item }">{{item.StaffName}}</template>
                    <template v-slot:item.offdate="{ item }">{{item.OffChinaDate}}</template>
                    <template v-slot:item.shipmentInfo="{ item }">{{item.ShipmentInfo}}</template>
                </v-data-table>
                <v-row class="packhai-card-padding">
                    <v-col cols="12" class="pa-3">
                        <div class="text-center" @click="changePage()">
                        <v-pagination
                            v-model="page"
                            :length="pageLength"
                            :total-visible="pageTotalVisible"
                            color="error"
                        ></v-pagination>
                        </div>
                    </v-col>
                    </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" scrollable max-width="70%" persistent >
            <v-card>
                <v-stepper v-model="step1" alt-labels class="overflow-y-auto" >
                    <v-stepper-header>
                        <!-- เพิ่ม Tracking   -->
                        <v-stepper-step
                            :complete="step1 > 1"
                            step="1"
                        > 添加运单号 </v-stepper-step>
                        <v-divider/>
                        <!-- เพิ่ม packing   -->
                        <v-stepper-step
                            :complete="step1 > 2"
                            step="2"
                        > 添加包裹 </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items >
                        <v-stepper-content step="1" >
                            <v-card class="mb-12" >
                                <v-card-text>
                                    <div >
                                        <v-row row>
                                            <v-col class="pb-0" cols="12" md="3">
                                                <!-- น้ำหนัก -->
                                                <h4>重量 : </h4>
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="4">
                                                <v-text-field
                                                    ref="reftrackweight"
                                                    @keyup.enter="$refs.reftrackwidth.focus()"
                                                    @keypress="isNumberWNoDot($event)"
                                                    :value="tracking.weight"
                                                    @change="value => tracking.weight = value"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    autocomplete="off"
                                                    />
                                            </v-col>
                                        </v-row>
                                        <v-row row>
                                            <v-col class="pb-0" cols="12" md="3">
                                                <!-- กว้าง -->
                                                <h4>宽 : </h4>
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="4">
                                                <v-text-field
                                                    :value="tracking.width"
                                                    @change="value => tracking.width = value"
                                                    ref="reftrackwidth"
                                                    @keyup.enter="$refs.reftracklength.focus()"
                                                    @keypress="isNumberWNoDot($event)"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    autocomplete="off"
                                                    />
                                            </v-col>
                                        </v-row>
                                        <v-row row>
                                            <v-col class="pb-0" cols="12" md="3">
                                                <!-- ยาว -->
                                                <h4>长 : </h4>
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="4">
                                                <v-text-field
                                                    :value="tracking.length"
                                                    @change="value => tracking.length = value"
                                                    ref="reftracklength"
                                                    @keyup.enter="$refs.reftrackheight.focus()"
                                                    @keypress="isNumberWNoDot($event)"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    autocomplete="off"
                                                    />
                                            </v-col>
                                        </v-row>
                                        <v-row row>
                                            <v-col class="pb-0" cols="12" md="3">
                                                <!-- สูง -->
                                                <h4>高 : </h4>
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="4">
                                                <v-text-field
                                                    :value="tracking.height"
                                                    @change="value => tracking.height = value"
                                                    ref="reftrackheight"
                                                    @keyup.enter="$refs.reftrackingnumber.focus()"
                                                    @keypress="isNumberWNoDot($event)"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    autocomplete="off"
                                                    />
                                            </v-col>
                                        </v-row>
                                        <v-row row>
                                            <v-col class="pb-0" cols="12" md="3">
                                                <!-- track -->
                                                <h4>运单号 : </h4>
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="4">
                                                <v-text-field
                                                    v-model="tracking.number"
                                                    ref="reftrackingnumber"
                                                    @keyup.enter="addtrack()"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    autocomplete="off"
                                                    />
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="5">
                                              <!-- เพิ่ม -->
                                                <v-btn color="deep-orange" dark @click="addtrack()">添加</v-btn>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-row row><v-col cols="12" align="right"></v-col></v-row> -->
                                        <v-row row class="mb-2">
                                            <!-- จน.แทรค -->
                                            <v-col class="pb-0" cols="12" md="3"><h4> 运单数量 : </h4></v-col>
                                            <v-col class="pb-0" cols="12" md="4"><span>{{dataTableTrack.length}}</span> </v-col>
                                        </v-row>
                                        <v-data-table
                                            :headers="headersTrack"
                                            :items="dataTableTrack"
                                            class="elevation-0 packhai-border-table"
                                            hide-default-footer>
                                            <template v-slot:item.delete="{ item }">
                                                <v-btn icon><v-icon size="18" @click="deletetrack(item)">fa-trash-alt</v-icon></v-btn>
                                            </template>
                                            <template v-slot:item.trackno="{ item }">{{item.trackingno}}</template>
                                            <template v-slot:item.trackweight="{ item }">{{item.weight}}</template>
                                            <template v-slot:item.trackwidth="{ item }">{{item.width}}</template>
                                            <template v-slot:item.tracklenght="{ item }">{{item.length}}</template>
                                            <template v-slot:item.trackheight="{ item }">{{item.height}}</template>
                                        </v-data-table>
                                    </div>
                                    <div>
                                        <v-row>
                                            <!-- ยกเลิก -->
                                            <v-col cols="6" align="left" ><v-btn color="primary" @click="dialog=false">取消</v-btn></v-col>
                                            <v-col cols="6" align="right">
                                                <!-- ถัดไป -->
                                                <v-btn color="green lighten-1" dark @click="savetrack()" > 下一部  </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card
                            class="mb-12"
                            >
                                <v-card-text>
                                    <div>
                                        <v-row row>
                                            <v-col class="pb-0" cols="12" md="3">
                                                <h4>包裹号码 : </h4>
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="4">
                                                <v-text-field
                                                    ref="refpackingnumber"
                                                    @keyup.enter="$refs.refpackingweight.focus()"
                                                    v-model="packing.number"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    autocomplete="off"
                                                    />
                                            </v-col>
                                        </v-row>
                                        <v-row row>
                                            <v-col class="pb-0" cols="12" md="3">
                                                <!-- น้ำหนัก -->
                                                <h4>重量 : </h4>
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="4">
                                                <v-text-field
                                                    ref="refpackingweight"
                                                    @keyup.enter="$refs.refpackwidth.focus()"
                                                    v-model="packing.weight"
                                                    :value="packing.height"
                                                    @change="value => packing.height = value"
                                                    @keypress="isNumberWNoDot($event)"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    autocomplete="off"
                                                    />
                                            </v-col>
                                        </v-row>
                                        <v-row row>
                                            <v-col class="pb-0" cols="12" md="3">
                                                <!-- กว้าง -->
                                                <h4>宽 : </h4>
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="4">
                                                <v-text-field
                                                    ref="refpackwidth"
                                                    @keyup.enter="$refs.refpacklength.focus()"
                                                    :value="packing.width"
                                                    @change="value => packing.width = value"
                                                    @keypress="isNumberWNoDot($event)"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    autocomplete="off"
                                                    />
                                            </v-col>
                                        </v-row>
                                        <v-row row>
                                            <v-col class="pb-0" cols="12" md="3">
                                                <!-- ยาว -->
                                                <h4>长 : </h4>
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="4">
                                                <v-text-field
                                                    ref="refpacklength"
                                                    @keyup.enter="$refs.refpackheight.focus()"
                                                    :value="packing.length"
                                                    @change="value => packing.length = value"
                                                    @keypress="isNumberWNoDot($event)"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    autocomplete="off"
                                                    />
                                            </v-col>
                                        </v-row>
                                        <v-row row>
                                            <v-col class="pb-0" cols="12" md="3">
                                                <!-- สูง -->
                                                <h4>高 : </h4>
                                            </v-col>
                                            <v-col class="pb-0" cols="12" md="4">
                                                <v-text-field
                                                    ref="refpackheight"
                                                    @keyup.enter="savepacking()"
                                                    :value="packing.height"
                                                    @change="value => packing.height = value"
                                                    @keypress="isNumberWNoDot($event)"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    autocomplete="off"
                                                    />
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-row>
                                            <v-col cols="6" align="left">
                                                <!-- <v-btn icon @click="step1 = 1"><v-icon>mdi-arrow-left</v-icon></v-btn> -->
                                                <!-- ย้อนกลับ -->
                                                <v-btn color="primary" @click="step1 = 1" dark > 返回</v-btn>
                                            </v-col>
                                            <!-- บันทึก -->
                                            <v-col cols="6" align="right"><v-btn color="green lighten-1" dark @click="savepacking()" > 保存</v-btn></v-col>
                                        </v-row>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogtrack" scrollable max-width="50%" persistent>
            <v-card>
                <v-card-title>
                    <!-- รายละเอียด -->
                    详细
                    <v-spacer/>
                    <v-btn icon  @click="dialogtrack = false" > <v-icon>mdi-close</v-icon> </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                    :headers="headersTrack"
                    :items="detailtrack"
                    class="elevation-0 packhai-border-table"
                    hide-default-footer>
                        <template v-slot:item.delete="{ item }">
                            <v-btn icon @click="deletetrackinpacking(item)"><v-icon size="18">fa-trash-alt</v-icon></v-btn>
                        </template>
                        <template v-slot:item.trackno="{ item }">{{item.TrackingNo}}</template>
                        <template v-slot:item.trackweight="{ item }">{{item.Weight}}</template>
                        <template v-slot:item.trackwidth="{ item }">{{item.Cubic1}}</template>
                        <template v-slot:item.tracklenght="{ item }">{{item.Cubic2}}</template>
                        <template v-slot:item.trackheight="{ item }">{{item.Cubic3}}</template>

                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <!-- บันทึก -->
                    <v-btn color="green " dark @click="updatedeletedetailtrack()" > 保存 </v-btn>
                </v-card-actions>
                <!-- <v-card-actions> <v-btn color="deep-purple lighten-2" text @click="dialogtrack = false" > ตกลง </v-btn>
                    </v-card-actions> -->
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogeditpacking" scrollable max-width="40%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card-title>
                    <!-- แก้ไข Packing  -->
                    修改包裹
                    <v-spacer/>
                    <v-btn icon  @click="dialogeditpacking = false" > <v-icon>mdi-close</v-icon> </v-btn>
                </v-card-title>
                <v-card-text>
                    <div>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="5">
                                <!-- PackingNo -->
                                <h4>麻袋号 : </h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="7">
                                <v-text-field
                                    ref="refpackingnumber"
                                    @keyup.enter="$refs.refpackingweight.focus()"
                                    :value="editedItem.ID"
                                    disabled
                                    outlined
                                    dense
                                    hide-details
                                    autocomplete="off"
                                    />
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="5">
                                <!-- น้ำหนัก -->
                                <h4>重量 : </h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="7">
                                <v-text-field
                                    ref="refeditpackingweight"
                                    @keyup.enter="$refs.refpackwidth.focus()"
                                    :value="editedItem.Weight"
                                    @change="value => editedItem.Weight = value"
                                    @keypress="isNumberWNoDot($event)"
                                    outlined
                                    dense
                                    hide-details
                                    autocomplete="off"
                                    />
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="5">
                                <!-- กว้าง -->
                                <h4>宽 : </h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="7">
                                <v-text-field
                                    ref="refeditpackwidth"
                                    @keyup.enter="$refs.refeditpacklength.focus()"
                                    :value="editedItem.Cubic1"
                                    @change="value => editedItem.Cubic1 = value"
                                    @keypress="isNumberWNoDot($event)"
                                    outlined
                                    dense
                                    hide-details
                                    autocomplete="off"
                                    />
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="5">
                                <!-- ยาว -->
                                <h4>长 : </h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="7">
                                <v-text-field
                                    ref="refeditpacklength"
                                    @keyup.enter="$refs.refeditpackheight.focus()"
                                    :value="editedItem.Cubic2"
                                    @change="value => editedItem.Cubic2 = value"
                                    @keypress="isNumberWNoDot($event)"
                                    outlined
                                    dense
                                    hide-details
                                    autocomplete="off"
                                    />
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="5">
                                <!-- สูง -->
                                <h4>高 : </h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="7">
                                <v-text-field
                                    ref="refeditpackheight"
                                    @keyup.enter="$refs.refeditpackoffchinadate.focus()"
                                    :value="editedItem.Cubic3"
                                    @keypress="isNumberWNoDot($event)"
                                    @change="value => editedItem.Cubic3 = value"
                                    outlined
                                    dense
                                    hide-details
                                    autocomplete="off"
                                    />
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="5">
                                <!-- <h4>วันที่ออกจากจีน : </h4> -->
                                <h4>出货日期 : </h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="7">
                                <v-radio-group row class="mt-2" v-model="Dateoutoffchina">
                                    <v-radio v-for="i in Dateoutoffchina_list" color="info" v-bind:key="i['value']" :label="i['label']" :value="i['value']"></v-radio>
                                </v-radio-group>
                                <v-menu
                                    v-if="Dateoutoffchina==1"
                                    ref="menu_edit_date_china"
                                    v-model="menu_edit_date_china"
                                    :close-on-content-click="false"
                                    :return-value.sync="editedItem.OffChinaDate"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    :value="computed_edit_date_of_china"
                                    @change="value => computed_edit_date_of_china = value"
                                    autocomplete="off"
                                    outlined
                                    prepend-inner-icon="mdi-calendar"
                                    hide-details
                                    dense
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker v-model="editedItem.OffChinaDate" @input="menu_edit_date_china = false, $refs.menu_edit_date_china.save(editedItem.OffChinaDate)" no-title :allowed-dates="allowedDates" class="my-0">
                                </v-date-picker>
                            </v-menu>
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="5">
                                <!-- รอบ -->
                                <h4>批 : </h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="7">
                                <v-text-field
                                    ref="refeditpackshipment"
                                    @keyup.enter="Editpacking()"
                                    :value="editedItem.ShipmentInfo"
                                    @change="value => editedItem.ShipmentInfo = value"
                                    outlined
                                    dense
                                    hide-details
                                    autocomplete="off"
                                    />
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <!-- ตกลง -->
                <v-card-actions><v-spacer></v-spacer> <v-btn color="green lighten-1" dark  @click="Editpacking()"> 确认 </v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogoutof" scrollable max-width="30%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card-title>
                    <!-- ออกของจากจีน -->
                    总共
                    <v-spacer></v-spacer>
                    <v-btn icon  @click="dialogoutof=false" > <v-icon>mdi-close</v-icon> </v-btn>
                </v-card-title>
                <v-card-text>
                    <div>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="4">
                                <!-- <h4>ทั้งหมด : </h4> -->
                                <h4>总数 :</h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="8">
                                <v-text-field
                                    ref="refoutofnumber"
                                    @keyup.enter="$refs.refoutofdateoffchina.focus()"
                                    :value="dataoutof.number"
                                    @change="value => dataoutof.number = value"
                                    outlined
                                    disabled
                                    dense
                                    hide-details
                                    autocomplete="off"
                                    />
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="4">
                                <!-- วันที่ -->
                                <h4>日期 : </h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="8">
                                <v-menu
                                ref="search_menu_date_china"
                                v-model="search_menu_date_china"
                                :close-on-content-click="false"
                                :return-value.sync="date_of_china"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            ref="refoutofdateoffchina"
                                            :value="date_of_china"
                                            @change="value => computed_date_of_china = value"
                                            @keyup.enter="$refs.refoutofshipmentInfo.focus()"
                                            autocomplete="off"
                                            outlined
                                            prepend-inner-icon="mdi-calendar"
                                            hide-details
                                            dense
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_of_china" @input="search_menu_date_china = false, $refs.search_menu_date_china.save(date_of_china)" no-title :allowed-dates="allowedDates" class="my-0">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="4">
                                <!-- <h4>รอบ : </h4> -->
                                <h4>批 : </h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="8">
                                <v-text-field
                                    ref="refoutofshipmentInfo"
                                    @keyup.enter="outofchina()"
                                    :value="dataoutof.shipmentInfo"
                                    @change="value => dataoutof.shipmentInfo = value"
                                    outlined
                                    dense
                                    hide-details
                                    autocomplete="off"
                                    />
                            </v-col>
                        </v-row>

                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <!-- ตกลง -->
                    <v-btn color="green lighten-1" dark @click="outofchina()" > 保存 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import { chinaPackingService } from '@/website/global'
  import { isNumberWithDot, isNumberWNoDot, } from '@/website/global_function'
  export default {
    components: {
        Loading
    },
    data: () => ({
      page_loading: true,
    //   page_loading: false,
      loading: false,
      dialog: false,
      dialogtrack: false,
      dialogeditpacking: false,
      dialogoutof: false,
      search_menu_date_from: false,
      search_menu_date_to: false,
      search_menu_date_china: false,
      menu_edit_date_china: false,
      step1: 1,

      // paginations
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 50,
      offsets: 0,

      offsetTop: 0,

      deleteindextrack: -1,

      search_date_from: null,
      search_date_to: null,
      search_date_edit_china: null,
      date_of_china: new Date().toISOString().substr(0, 10),
      search : {
        "packing": '',
        "track": '',
      },
      tracking: {
        "number": '',
        "weight": '',
        "width": '',
        "length": '',
        "height": ''
      },
      packing: {
        "number": '',
        "weight": '',
        "width": '',
        "length": '',
        "height": ''
      },
      dataoutof: {
          "number": "",
          "shipmentInfo": "",
      },
      headers:[
        { text: '修改', align: 'center', value: 'edit', sortable: false, }, //แก้ไข
        { text: '麻袋号 ', align: 'center' , value: 'PackingID', sortable: false,  }, //เลขกระสอบ
        { text: '收入时间', align: 'center',  value: 'created', sortable: false, }, //Track
        { text: '重量', align: 'center', value: 'weight',  sortable: false,},//น้ำหนัก
        { text: '宽', align: 'center', value: 'width', sortable: false }, //กว้าง
        { text: '长', align: 'center', value: 'lenght', sortable: false,  },
        { text: '高', align: 'center', value: 'height', sortable: false,},
        { text: '制作人', align: 'center', value: 'by', sortable: false,  }, //สร้างโดย
        { text: '出货日期', align: 'center', value: 'offdate', sortable: false,  width:'150px' }, //ของออกจากจีนวันที่
        { text: '遍', align: 'center', value: 'shipmentInfo', sortable: false,  width:'150px' }, //รอบ
      ],
      headersTrack:[
        { text: '删除', align: 'center', value: 'delete', sortable: false }, //ลบ
        { text: '运单号', align: 'center' , value: 'trackno', sortable: false },
        { text: '重量', align: 'center', value: 'trackweight',  sortable: false,}, //น้ำหนัก
        { text: '宽', align: 'center', value: 'trackwidth', sortable: false }, //กว้าง
        { text: '长', align: 'center', value: 'tracklenght', sortable: false,  },
        { text: '高', align: 'center', value: 'trackheight', sortable: false,},
      ],
    //   headersdetailtrack:[
    //     { text: 'Tracking', align: 'center' , value: 'dtrackno', sortable: false },
    //     { text: 'น้ำหนัก', align: 'center', value: 'dtrackweight',  sortable: false,},
    //     { text: 'กว้าง', align: 'center', value: 'dtrackwidth', sortable: false },
    //     { text: 'ยาว', align: 'center', value: 'dtracklenght', sortable: false,  },
    //     { text: 'สูง', align: 'center', value: 'dtrackheight', sortable: false,},
    //   ],
      dataTable: [],
      dataTableTrack: [],
      detailtrack:[],
      data_export:[],

      singleSelect: false,
      selected: [],
      editedItem:[],
      deletedetailtrack: [],
      Dateoutoffchina: 0,
      Dateoutoffchina_list: [
        {"label": "未出货", "value": 0}, //ยังไม่ออกของจากจีน
        {"label": "出货日期", "value": 1} //วันที่ออกของจากจีน
      ],
    }),
    async created() {
        await this.getsearchdatapack()
        this.page_loading = false

    },
    computed: {
      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },

      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },

      computed_date_of_china() {
        return this.formatDates(this.date_of_china)
      },

      computed_edit_date_of_china() {
        return this.formatDateChina(this.editedItem.OffChinaDate)
      },

      computed_date_transfer() {
        return this.formatDateTransfer(this.date_transfer)
      },
    },
    methods: {
      isNumberWithDot,
      isNumberWNoDot,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),
      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },
      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },
      formatDateChina (date) {
        if (date == null) return null
        if (date != null) {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },
      SetSearch(search){
        localStorage.setItem('search', JSON.stringify(search))
      },
      async getsearchdatapack(){
        this.loading = true
        var Data = {
            'ShopID': localStorage.getItem("shop_id"),
            'search': this.search,
            'limits': this.limits,
            'offsets': this.offsets,
            'search_date_from': this.search_date_from,
            'search_date_to': this.search_date_to
        }
        var response = await axios.post(chinaPackingService+'ChinaPacking/get_china_packing', {
            'Data' : Data
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.Status) {
            this.dataTable = response.data.Data.GetDataPack
            this.CountPage = this.dataTable.length
            this.pageLength =  (Math.ceil(this.CountPage/this.limits))
            if (this.pageLength<=0){this.pageLength=1}
            this.pageTemp = this.page
            this.SetSearch(this.search)
            if (response.data.Data.Date != null) {
                this.date_range = response.data.Data.Date
                if (this.date_range.length == 0) {
                  var nowDate = new Date()
                  this.search_date_to = nowDate.toISOString().substr(0, 10)
                  nowDate.setDate(nowDate.getDate()-7)
                  this.search_date_from = nowDate.toISOString().substr(0, 10)
                } else {
                  this.search_date_from = this.date_range[0]
                  this.search_date_to = this.date_range[1]
                }
            }
        }
        else{
            this.$swal({
                  type: 'warning',
                  title: '有一些错误 请稍后重试！！',
                })
        }
        this.loading = false
      },
      async Addtracking(){
          this.dialog = true
      },
      async addtrack(){
        if(this.tracking.number == null || this.tracking.number == ''){
            this.$refs.reftrackingnumber.focus()
            return
        }
        if(this.tracking.weight == null || this.tracking.weight == ''){
              this.tracking.weight = 0
        }
        if(this.tracking.width == null || this.tracking.width == ''){
              this.tracking.width = 0
        }
        if(this.tracking.length == null || this.tracking.length == ''){
              this.tracking.length = 0
        }
        if(this.tracking.height == null || this.tracking.height == ''){
            this.tracking.height = 0
        }
        var trackingindex = null
        for (var index in this.dataTableTrack) {
            if (this.dataTableTrack[index]['trackingno'] == this.tracking.number) {
                trackingindex = index
                break
            }
        }
        if (trackingindex != null){
            this.dataTableTrack.splice(trackingindex, 1)
        }
        this.pushdatatabletrack()
        this.cleardatatrack()
      },
      pushdatatabletrack(){
        var index = 0;
        this.dataTableTrack.splice(index, 0, {
            "trackingno": this.tracking.number,
            "weight": this.tracking.weight,
            "width": this.tracking.width,
            "length": this.tracking.length,
            "height": this.tracking.height
        })
      },
      async savetrack(){
        if(this.dataTableTrack.length == 0){
            this.$refs.reftrackingnumber.focus()
            return
        }
        this.step1 = 2
      },
      async loaddetailtrack(packingID){
        this.loading = true
        var Data = {
            'ShopID': localStorage.getItem("shop_id"),
            'PackID': packingID
        }
        var response = await axios.post(chinaPackingService+'ChinaPacking/get_china_tracking', {
            'Data' : Data
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.Status) {
            this.detailtrack = response.data.Data.Detailtrack
            this.dialogtrack = true
        }
        this.loading = false
      },
      cleardatatrack(){
        this.tracking = {
            "number": '',
            "weight": '',
            "width": '',
            "length": '',
            "height": ''
        }
      },
      cleardatapacking(){
          this.packing = {
            "number": '',
            "weight": '',
            "width": '',
            "length": '',
            "height": ''
          }
      },
      async savepacking(){
        if(this.packing.weight == null || this.packing.weight == ''){
            this.packing.weight = 0
        }
        if(this.packing.width == null || this.packing.width == ''){
            this.packing.width = 0
        }
        if(this.packing.length == null || this.packing.length == ''){
            this.packing.length = 0
        }
        if(this.packing.height == null || this.packing.height == ''){
            this.packing.height = 0
        }
        this.loading = true
        var Data = {
            'StaffID': localStorage.getItem("staff_id"),
            'ShopID': localStorage.getItem("shop_id"),
            'Tracking' : this.dataTableTrack,
            'Packing' : this.packing
        }
        let response = await axios.post(chinaPackingService+'ChinaPacking/post_china_packing_tracking', {
            'Data' : Data
        })
        if (response.data.Status) {
            this.$swal({
                  position: 'center',
                  type: 'success',
                  title: response.data.Data.Message,
                })
            this.step1 = 1
            this.dialog = false
            this.dataTableTrack =[]
            this.cleardatatrack()
            this.cleardatapacking()
            this.search_date_from = null
            this.search_date_to = null
            this.getsearchdatapack()
        }else{
            this.$swal({
                  type: 'warning',
                  title: '有一些错误 请稍后重试 ！！', //มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!
                })
        }
        this.loading = false
      },
      async changePage() {
        var pages = this.page
        if (this.pageLength!=1 && this.pageTemp!=this.page){
          this.offsets = (this.page*this.limits)-this.limits
          await this.SearchStockShop(this.limits,this.offsets,this.page)
          this.page = pages
          this.pageTemp = this.page
        }
      },
      async get_data_chinapacking_for_excel() {
          this.loading = true
          var Data = {
            'ShopID': localStorage.getItem("shop_id"),
            'search': this.search,
            'search_date_from': this.search_date_from,
            'search_date_to': this.search_date_to
        }
          let response = await axios.post(chinaPackingService + 'ChinaPacking/get_data_chinapacking_for_excel', {
            'Data' : Data
          })
          if (response.data.Status) {
            this.data_export = response.data.Data.data_excel
            this.date_data_export =  response.data.Data.date
          }
      },
      async Export_to_excel () {
          if(this.dataTable.length == 0){
            this.$swal({
              type: 'warning',
              title: 'No data !!',
            })
          }
          else {
            await this.get_data_chinapacking_for_excel()
            this.loading = true
            let response = await axios.post(chinaPackingService + 'ChinaPacking/export_excel', {
              'data_export': this.data_export
            })
            if (response.data.status == 'success') {
                var filename = response.data.this_path
                window.open(chinaPackingService + 'ChinaPacking/download/'+filename, "_blank");
                this.loading = false
            }
          }
      },
      async Edit(item){
        this.editedIndex = this.dataTable.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if (this.editedItem['OffChinaDate'] != null) {
            this.Dateoutoffchina = 1
            this.editedItem.OffChinaDate = this.formatDateChina(this.editedItem.OffChinaDate)
        }
        else{
            this.Dateoutoffchina = 0
            this.editedItem.OffChinaDate = new Date().toISOString().substr(0, 10)
        }
        this.dialogeditpacking = true
      },
      async Editpacking(){
        this.loading = true
        if (this.Dateoutoffchina == 0) {
            this.editedItem['OffChinaDate'] = null
        }
        var Dataedit = {
            'ShopID': localStorage.getItem("shop_id"),
            'editedItem': this.editedItem
        }
        var response = await axios.post(chinaPackingService+'ChinaPacking/update_china_packing', {
            'Data_Edit' : Dataedit
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.Status) {
            this.$swal({
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
                type: 'success',
                title: '更新成功 ', //อัพเดตข้อมูล
                })
            this.getsearchdatapack()
            this.dialogeditpacking = false
        }
        else{
            this.$swal({
                  type: 'warning',
                  title: '有一些错误 请稍后重试！！',
                })
        }
        this.loading = false
      },
      async outof(){
          this.dataoutof = {
              "number": this.selected.length,
          }
          if (this.selected.length > 0) {
            this.dialogoutof = true
          }
          else{
            this.$swal({
                type: 'warning',
                title: '选择项目', //กรุณาเลือกข้อมูลก่อน
            })
          }
      },
      async outofchina(){
        this.loading = true
        var Dataoutofchina = {
            'ShopID': localStorage.getItem("shop_id"),
            'Select': this.selected,
            'outof': this.dataoutof,
            'Date': this.date_of_china,
        }
        var response = await axios.post(chinaPackingService+'ChinaPacking/update_out_of_china_packing', {
            'Dataoutofchina' : Dataoutofchina
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.Status) {
            this.$swal({
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
                type: 'success',
                title: '更新成功 ',
                })
            this.getsearchdatapack()
            this.singleSelect = false,
            this.dialogoutof = false
        }
        else{
            this.$swal({
                  type: 'warning',
                  title: '有一些错误 请稍后重试！！',
                })
        }
        this.loading = false
      },
      async deletetrackinpacking(TrackingNo){
        this.$swal({
                position: "center",
                type: 'warning',
                title: '是否删除 !!', //'ลบ ใช่หรือไม่ !!',
                confirmButtonText: '确定',//'ยืนยัน',
                cancelButtonText:  '取消' ,//'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
              }).then(async(result) => {
                if (result.value) {
                    this.deleteindextrack = this.detailtrack.indexOf(TrackingNo)
                    this.deletedetailtrack.push({
                        "tracking" : TrackingNo['TrackingNo']
                        })
                    this.detailtrack.splice(this.deleteindextrack, 1)
                }
        })
      },
      async updatedeletedetailtrack(){
        this.loading = true
        var response = await axios.post(chinaPackingService+'ChinaPacking/delete_china_packing_intracking', {
            'ShopID': localStorage.getItem("shop_id"),
            'Datadetailtrack' : this.deletedetailtrack
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.Status) {
            this.dialogtrack = false
            this.$swal({
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
                type: 'success',
                title: '成功删除',
                })
        }
        this.loading = false
      },
      deletetrack(trackingno){
        this.deleteindextrack = this.dataTableTrack.indexOf(trackingno)
        this.dataTableTrack.splice(this.deleteindextrack, 1)
      },
      onScroll (e) {
        this.offsetTop = e.target.scrollTop
      },
    }
  }
</script>
<style>
.stepper {
  overflow: visible;
}
</style>
